// Typography
//
// Headings, body text, lists, and other misc typographic elements.

@font-face {
  font-family: 'OpticianSans';
  font-display: swap;
  src: url('/assets/fonts/Optiker-K.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Optiker-K.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/Optiker-K.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/Optiker-K.woff') format('woff'), /* Pretty Modern Browsers */
       url('/assets/fonts/Optiker-K.ttf')  format('truetype'), /* Safari, Android, iOS */
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-weight: 600;
  line-height: 1.25;
  color: #313131;
  text-rendering: optimizeLegibility;
}

h1 {
  font-size: 2rem;
}

h2 {
  margin-top: 1rem;
  font-size: 1.5rem;
}

h3 {
  margin-top: 1.5rem;
  font-size: 1.25rem;
}

h4, h5, h6 {
  margin-top: 1rem;
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul, ol, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: .5rem;
}

hr {
  position: relative;
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #fff;
}

abbr {
  /* Add this attribute to the element that needs a tooltip */
  &[title] {
    position: relative;
    z-index: 2;
    cursor: pointer;
    text-decoration: none;
  }

  /* Hide the tooltip content by default */
  &[title]:before {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }

  /* Show tooltip content on hover */
  &[title]:hover:before {
    visibility: visible;
    opacity: 0.9;
  }

  /* Position tooltip above the element */
  &[title]:before {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    margin-bottom: .25rem;
    margin-left: -3.5rem;
    padding: .35rem;
    width: 6rem;
    border-radius: 5px;
    background-color: $gray-5;
    color: #fff;
    content: attr(title);
    text-align: center;
    font-size: 60%;
    font-family: $code-font-family;
    line-height: 1.2;
  }
}

blockquote {
  padding: .5rem 1rem;
  margin: .8rem 0;
  color: #7a7a7a;
  border-left: .25rem solid #e5e5e5;

  p:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 30em) {
    padding-right: 5rem;
    padding-left: 1.25rem;
  }
}

figure {
  margin: 0;
}


// Markdown footnotes
//
// See the example content post for an example.

// Footnote number within body text
a[href^="#fn:"],
// Back to footnote link
a[href^="#fnref:"] {
  display: inline-block;
  margin-left: .1rem;
  font-weight: bold;
}

// List of footnotes
.footnotes {
  margin-top: 2rem;
  font-size: 85%;
}

// Custom type
//
// Extend paragraphs with `.lead` for larger introductory text.

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
