// Masthead
//
// Super small header above the content for site name and short description.

.masthead {
  padding-top:    1rem;
  padding-bottom: 1rem;
  margin-bottom: 3rem;
  font-family: $logo-font-family;
}

.masthead-title {
  margin-top: 0;
  margin-bottom: 0;
  color: $gray-4;

  a {
    color: inherit;
  }

  a:hover {
    text-decoration: none;
    border-bottom: 6px solid $logo-pink;
  }

  #logo {
    color: $logo-blue;
    font-size: 175%;
  }
}

#nav {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  position: relative;
  z-index: 2000;
  width: 100%;
}

.nav-list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-transform: uppercase;
  font-size: 110%;

  a {
    color: $gray-4;
  }

  a:hover {
    color: $logo-pink;
    text-decoration: none;
  }

  li:not(:last-of-type) {
    margin-right: calc(1vw + 0.5em);
  }
}
