.projects {
  list-style: none;
  padding: 0;
  line-height: 1.7;
  position: relative;
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  li.grid-year {
    grid-column: 1 / 2;
    margin-bottom: 1.5rem;
  }

  li.grid-tag {
    grid-column: 2 / 3;
    line-height: 1rem;

    .tag {
      font-size: 0.8rem;
      line-height: 0.8rem;
      padding: 0.25rem 0.5rem;
      border-radius: 15px;
      display: inline-block;
      color: white;
      font-weight: 500;
      text-transform: capitalize;
    }

    .talk {
      background-color: $cyan;
    }
    .project {
      background-color: $orange;
    }
    .org {
      background-color: $red;
    }
    .event {
      background-color: $blue;
    }
  }

  li.grid-project {
    grid-column: 3 / -1;
    margin-bottom: 1.5rem;
  }

  li:hover h1 {
    color: $logo-pink;
  }

  a:hover {
    text-decoration: none;    
  }

  h1, h2 {
    color: $title-gray;
  }

  h1 {
    font-size: 1.4rem;
    color: $gray-5;
    line-height: 1.2rem;
    margin: 0 0 .2rem 0;
  }

  h2 {
    font-size: 1.1rem;
    color: $gray-4;
    font-weight: 300;
    line-height: 1rem;
    margin-top: .4rem;
    margin-bottom: .2rem;
  }

  .post-date,
  h3 {
    font-size: 0.8rem;
    margin: 0;
    display: block;
    color: $date-gray;
  }
}
