$gray-1: #f9f9f9;
$gray-2: #ccc;
$gray-3: #767676;
$gray-4: #515151;
$gray-5: #313131;

$date-gray: #9a9a9a;
$title-gray: #303030;

$red: #ac4142;
$orange: #d28445;
$yellow: #f4bf75;
$green: #90a959;
$cyan: #75b5aa;
$blue: #268bd2;
$brown: #8f5536;

$logo-blue: #0f5086;
$logo-pink: #de2b5d;

$logo-font-family: OpticianSans, sans-serif;

$root-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$root-font-size: 16px;
$root-line-height: 1.5;

$body-color: #515151;
$body-bg: #fff;
$link-color: $blue;

$border-color: #e5e5e5;

$large-breakpoint: 38em;
$large-font-size: 20px;

$code-font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$code-color: #bf616a;
